import { cx } from "@linaria/core";
import { FC, memo, MouseEvent } from "react";
import { useAppDispatch } from "@/hooks/redux";
import { modalsAlias, setIsShowModal } from "@/store/reducers/commonSlice";
import { Container } from "@/styles/utils/StyledGrid";
import { cssAuthGuardInvalid } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { cssIsActive, cssItem, MenuBar, StyledCaption, StyledFooterBar } from "./Styled";
import { MenuLinkIconI } from "./types";
export const TapBar: FC<MenuLinkIconI> = memo(({
  data,
  isAuth,
  route
}) => {
  const dispatch = useAppDispatch();
  const isActive = (path: string): boolean => route === path;
  const getIsAuthResolve = (isAuthGuard: boolean) => isAuthGuard ? isAuth : true;
  const onClickHandle = (isAuthResolve: boolean, {
    clickCb
  }: {
    clickCb?: () => void;
  } = {}) => (e: MouseEvent<HTMLButtonElement>) => {
    if (isAuthResolve) {
      clickCb?.();
      return;
    }
    e.preventDefault();
    dispatch(setIsShowModal({
      isShow: true,
      alias: modalsAlias.implementAuth
    }));
  };
  return <StyledFooterBar>
      <Container>
        <MenuBar>
          {data.map(({
          title,
          icon,
          path,
          isAuthGuard = false,
          caption,
          click
        }) => {
          const isAuthResolve = getIsAuthResolve(isAuthGuard);
          const captionElement = <>
                  {!!caption ? <StyledCaption>{caption(title)}</StyledCaption> : title}
                </>;
          let className = cx(cssItem, !isAuthResolve && cssAuthGuardInvalid);
          if (!click && !!path) {
            className = cx(className, isActive(path) && cssIsActive);
          }
          return <Button key={title} isClean className={className} icon={icon} as={isAuthResolve && !!path ? "a" : undefined} href={isAuthResolve && !!path ? path : undefined} onClick={onClickHandle(isAuthResolve, {
            clickCb: click
          })}>
                  {captionElement}
                </Button>;
        })}
        </MenuBar>
      </Container>
    </StyledFooterBar>;
});
TapBar.displayName = "TapBar";