import { FC, useCallback } from "react"
import { useRouter } from "next/router"
import { MobileMenuStaticType } from "@/components/FooterBar/types"
import { Price } from "@/components/Products/parts"
import { PushCounter, PushCounterRow } from "@/components/PushCounter"
import { useAuth } from "@/hooks/auth"
import { useCart } from "@/hooks/cart/cart"
import { useModals } from "@/hooks/modals/useModals"
import { useWindowSize } from "@/hooks/useWindowSize"
import { modalsAlias } from "@/store/reducers/commonSlice"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { ROUTES } from "@/utils/constants"
import { TapBar } from "./TapBar"

const FooterBarContent: FC = () => {
  const { cartCount: qtyCart, totalCost } = useCart()
  const { isAuth } = useAuth()

  const { route } = useRouter()

  const { show } = useModals()

  const cartCaptionFn = useCallback(
    (title: string) =>
      totalCost > 0 ? (
        <>
          <PushCounterRow>
            <PushCounter qty={qtyCart} />
          </PushCounterRow>
          <Price price={totalCost} variant={"span"} isClean />
        </>
      ) : (
        <>{title}</>
      ),
    [qtyCart, totalCost],
  )

  const menu: MobileMenuStaticType[] = [
    {
      title: "Главная",
      path: ROUTES.home,
      icon: "Home",
      isAuthGuard: false,
      pageAlias: "home",
    },
    {
      title: "Каталог",
      icon: "Catalog",
      isAuthGuard: false,
      pageAlias: "catalog",
      click: () => {
        show({
          alias: modalsAlias.catalogMobile,
        })
      },
    },
    {
      title: "Корзина",
      path: ROUTES.cart,
      icon: "ShoppingCart",
      pageAlias: "cart",
      caption: cartCaptionFn,
    },
    {
      title: "Заказы",
      path: `${ROUTES.account}${ROUTES.historyAccount}`,
      icon: "Orders",
      isAuthGuard: true,
      pageAlias: "historyAccount",
    },
    {
      title: "Профиль",
      path: ROUTES.account,
      icon: "User",
      isAuthGuard: true,
      pageAlias: "account",
    },
  ]

  return <TapBar data={menu} isAuth={isAuth} route={route} />
}

const FooterBar = () => {
  const { width } = useWindowSize()
  const isLessLg =
    width !== undefined && width <= getBreakpointVal(breakpoints.lg)

  if (!isLessLg) {
    return null
  }

  return <FooterBarContent />
}

export { FooterBar }
